.paragraph {
  display: block;
}

.root {
  margin-top: var(--spacing-24);
  color: inherit;
  @media (--small) {
    max-width: var(--text-inset-width);
    margin-inline: auto;
    margin-top: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & a {
    display: inline-block;
  }

  & p {
    line-height: 1.4375;
  }

  & u {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--color-primary-80);
    text-decoration-thickness: 1px;
  }

  & h3 {
    @extend %typo-subtitle2;
    text-transform: uppercase;
    margin-bottom: var(--spacing-8);
  }

  & p + p {
    margin-top: 1rem;
  }

  & p + h3 {
    margin-top: var(--spacing-24);
  }

  & p a {
    color: var(--color-primary);
  }

  & ul {
    list-style: square;
    padding-inline-start: 2rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    & > li {
      margin-top: var(--body-margin-small);
      padding-left: 1rem;
    }
  }

  & ol {
    list-style: decimal;
    padding-inline-start: 2rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    & > li {
      margin-top: var(--body-margin-small);
      padding-left: 1rem;
    }
  }
}
