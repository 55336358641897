.navWrapper {
  background: var(--color-all-white);
  height: calc(var(--header-height) + var(--snackbar-height));
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: var(--color-divider) 1px solid;
  transition:
    transform 200ms linear,
    background 200ms;

  &:not:has(.snackbarOuter) {
    height: var(--header-height);
  }
}

.nav {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.right {
  display: flex;
  justify-content: flex-end;
  gap: 0;
  transform: translateX(calc((48px - 24px) / 2));
}

.left {
  display: flex;
  align-items: center;
  gap: var(--spacing-48);
  height: 100%;
}

.logo {
  width: 35px;
}

.logo svg {
  width: 100%;
  height: auto;
  display: block;

  --svg-color: var(--color-primary);
}

.hide:not(:hover) {
  transform: translateY(-100%);
}

.mobile {
  @media (--small) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (--small) {
    display: block;
    height: 100%;
  }
}

.snackbarOuter {
  @extend %typo-body3;

  background-color: var(--color-primary);
  height: var(--snackbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.snackbarItem {
  display: flex;
  transition: all 600ms;
  transform: translateY(20px) translateX(0);
  opacity: 0;
  position: absolute;
  justify-content: center;
  gap: 10px;
  text-align: center;

  &.active {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }

  &.prev {
    transform: translateY(-20px) translateX(0);
  }

  & img {
    filter: invert(1) brightness(1) saturate(1000%) brightness(10);
  }

  & span {
    color: white;
  }

  & a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
